import { useEffect, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { USDT_DECIMAL } from "../constants";
import { getFunctions, httpsCallable } from "firebase/functions";

const Balance = () => {
  const [user] = useAuthState(auth);
  const [balance, setBalance] = useState("...");
  const [checkingDeposit, setCheckingDeposit] = useState(false);

  useEffect(() => {
    return onSnapshot(doc(db, "users", user.uid), (userDoc) => {
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setBalance((userData.balance / 10 ** USDT_DECIMAL).toFixed(2));
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkDeposit = () => {
    if (!checkingDeposit) {
      const functions = getFunctions();
      const checkDepositFunction = httpsCallable(functions, 'checkDeposit');
      setCheckingDeposit(true);
      checkDepositFunction().catch((e) => {
        console.error(e);
      }).finally(() => {
        setCheckingDeposit(false);
      });
    }
  };

  return (
    <>
      <div className="pt-6 pr-6 pb-4 pl-4 mt-2 mb-4 bg-white rounded-lg drop-shadow-[0_8px_24px_rgba(5,102,205,0.16)] text-[#5F657E] leading-4">
        <div className="inline-block mb-3 font-medium">
          Үлдэгдэл
        </div>
        <div className="flex items-center">
          <div className="grow font-bold text-2xl">
            {balance} USDT
          </div>
          <svg className={checkingDeposit ? "animate-spin" : ""} onClick={checkDeposit} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" rx="24" fill="#E7F6FF" />
            <path d="M23.6813 13C21.6856 12.9973 19.7293 13.5544 18.0345 14.6082C16.3397 15.6619 14.9745 17.17 14.0941 18.9609C13.2137 20.7518 12.8534 22.7539 13.0541 24.7394C13.2548 26.7249 14.0085 28.6143 15.2295 30.1929C16.4505 31.7714 18.0898 32.9757 19.9612 33.669C21.8325 34.3623 23.8608 34.5167 25.8156 34.1146C27.7703 33.7126 29.573 32.7703 31.0189 31.3947C32.4647 30.0192 33.4957 28.2656 33.9946 26.3333H31.22C30.731 27.7163 29.8713 28.9382 28.7347 29.8654C27.5982 30.7927 26.2286 31.3896 24.7756 31.5909C23.3227 31.7923 21.8424 31.5903 20.4965 31.0071C19.1506 30.4238 17.991 29.4818 17.1444 28.2839C16.2978 27.0861 15.7969 25.6785 15.6963 24.2152C15.5957 22.7518 15.8994 21.2889 16.5742 19.9865C17.249 18.6841 18.2688 17.5923 19.5223 16.8304C20.7757 16.0686 22.2145 15.666 23.6813 15.6667C24.7305 15.6682 25.7688 15.8789 26.7356 16.2864C27.7024 16.6939 28.5783 17.2901 29.312 18.04L25.0213 22.3333H34.3546V13L31.22 16.1333C30.2322 15.1396 29.0576 14.3511 27.7637 13.8133C26.4699 13.2756 25.0824 12.9992 23.6813 13Z" fill="#13A9FD" />
          </svg>
        </div>
      </div>
    </>
  )
}

export default Balance;
