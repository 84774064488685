import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAUIdGvzjk4I7PCcSvOiuXl-Y0LfEyGAE4",
  authDomain: "peer2payment.firebaseapp.com",
  projectId: "peer2payment",
  appId: "1:521965814010:web:aaf176c765a41a3c374943",
  measurementId: "G-22EMR9PXMN"
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
