import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./Navbar"

const Layout = () => {
  return (
    <>
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <div className="container mx-auto max-w-[1140px] px-4 flex-1">
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Layout;
