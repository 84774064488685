import { doc, onSnapshot } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import { Context } from "../App";
import { auth, db } from "../firebase";
import Balance from "./Balance";
import Head from "./Head";

const Profile = () => {
  const [user] = useAuthState(auth);
  const [bankCountry, setBankCountry] = useState("Mongolia");
  const [bank, setBank] = useState();
  const [bankAccountNumber, setBankAccountNumber] = useState();
  const [bankAccountName, setBankAccountName] = useState();
  const [bankAccountCurrency, setBankAccountCurrency] = useState();
  const [isMerchant, setIsMerchant] = useState(false);
  const [updating, setUpdating] = useState(false);
  const {getText} = useContext(Context);
  const userRef = doc(db, "users", user.uid);

  useEffect(() => {
    return onSnapshot(userRef, (userDoc) => {
      if (userDoc.exists()) {
        const data = userDoc.data();
        setBankCountry(data.bankCountry);
        setBank(data.bank);
        setBankAccountNumber(data.bankAccountNumber);
        setBankAccountName(data.bankAccountName);
        setBankAccountCurrency(data.bankAccountCurrency);
        setIsMerchant(data.isMerchant);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const update = () => {
    setUpdating(true);

    const functions = getFunctions();
    const updateBankInfoFunction = httpsCallable(functions, "updateBankInfo");
    updateBankInfoFunction({
      bankCountry: bankCountry,
      bank: bank,
      bankAccountNumber: bankAccountNumber,
      bankAccountName: bankAccountName,
      bankAccountCurrency: bankAccountCurrency
    }).then(() => {
      setUpdating(false);
    });
  };

  return (
    <>
      <Head>{getText("profile")}</Head>

      <Balance />

      <div className="flex gap-4">
        <Link className="basis-1/2 block bg-[#E6F9F9] text-[#00C6C4] p-4 font-medium rounded-lg drop-shadow-[0_8px_24px_rgba(5,102,205,0.16)]" to="/deposit">
          <svg className="inline mr-4" width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.6 4.88889V2.44444C21.6 1.09633 20.5236 0 19.2 0H3.6C1.6152 0 0 1.64511 0 3.66667V18.3333C0 21.0234 2.1528 22 3.6 22H21.6C22.9236 22 24 20.9037 24 19.5556V7.33333C24 5.98522 22.9236 4.88889 21.6 4.88889ZM19.2 15.8889H16.8V11H19.2V15.8889ZM3.6 4.88889C3.29102 4.87481 2.99929 4.73989 2.78553 4.51222C2.57178 4.28455 2.45248 3.98168 2.45248 3.66667C2.45248 3.35165 2.57178 3.04878 2.78553 2.82111C2.99929 2.59344 3.29102 2.45852 3.6 2.44444H19.2V4.88889H3.6Z" fill="#00C6C4" />
            <path d="M5.78014 20.4957L5.78014 19.7457L5.03014 19.7457L3.18337 19.7457L7.75863 14.861L7.75872 14.8609C7.89821 14.7119 8.10429 14.7149 8.23817 14.8589L8.2402 14.8611L12.8168 19.7457L10.968 19.7457L10.218 19.7457L10.218 20.4957L10.218 26.25L5.78014 26.25L5.78014 20.4957Z" fill="#00C6C4" stroke="#E6E7EB" strokeWidth="1.5" />
          </svg>
          Цэнэглэх
        </Link>
        <Link className="basis-1/2 block bg-[#FEF0F0] text-[#F46C6C] p-4 font-medium rounded-lg drop-shadow-[0_8px_24px_rgba(5,102,205,0.16)]" to="/withdraw">
          <svg className="inline mr-4" width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.6 4.88889V2.44444C21.6 1.09633 20.5236 0 19.2 0H3.6C1.6152 0 0 1.64511 0 3.66667V18.3333C0 21.0234 2.1528 22 3.6 22H21.6C22.9236 22 24 20.9037 24 19.5556V7.33333C24 5.98522 22.9236 4.88889 21.6 4.88889ZM19.2 15.8889H16.8V11H19.2V15.8889ZM3.6 4.88889C3.29102 4.87481 2.99929 4.73989 2.78553 4.51222C2.57178 4.28455 2.45248 3.98168 2.45248 3.66667C2.45248 3.35165 2.57178 3.04878 2.78553 2.82111C2.99929 2.59344 3.29102 2.45852 3.6 2.44444H19.2V4.88889H3.6Z" fill="#F46C6C" />
            <path d="M10.2199 20.5043L10.2199 21.2543H10.9699H12.8166L8.24137 26.139L8.24128 26.1391C8.10179 26.2881 7.89571 26.2851 7.76183 26.1411L7.7598 26.1389L3.18316 21.2543H5.03196H5.78196V20.5043L5.78196 14.75L10.2199 14.75V20.5043Z" fill="#F46C6C" stroke="#E6F0FA" strokeWidth="1.5" />
          </svg>
          Гаргах
        </Link>
      </div>

      {isMerchant && <>
        <div className="mt-10 font-bold text-[#373F5E]">Банкны мэдээлэл</div>
        <div className="grid lg:grid-cols-2 gap-4 items-end px-4 py-6 mt-2 mb-[50px] bg-white rounded-lg drop-shadow-[0_8px_24px_rgba(5,102,205,0.16)]">
          <div className="relative">
            <div className="text-[#373F5E]">Банк</div>
            <select className="input w-48 appearance-none" value={bank} onChange={(e) => setBank(e.target.value)}>
              <option></option>
              <option>Хаан</option>
              <option>Хас</option>
              <option>ХХБ</option>
              <option>Голомт</option>
              <option>Төрийн</option>
              <option>Ариг</option>
              <option>Капитрон</option>
              <option>Богд</option>
              <option>Транс</option>
              <option>Чингис Хаан</option>
              <option>M</option>
            </select>
            <svg className="absolute right-4 bottom-9 pointer-events-none" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L7.5 7L14 1" stroke="#0566CD" strokeWidth="2" />
            </svg>
          </div>

          <div className="">
            <div className="text-[#373F5E]">Дансны дугаар</div>
            <input className="input" type="number" defaultValue={bankAccountNumber} onChange={(e) => setBankAccountNumber(e.target.value)} />
          </div>

          <div className="">
            <div className="text-[#373F5E]">Дансны нэр</div>
            <input className="input" type="text" defaultValue={bankAccountName} onChange={(e) => setBankAccountName(e.target.value)} />
          </div>

          <button className="button w-full h-[66px] my-2" onClick={update}>
            {updating && <>
              <svg className="inline animate-spin mr-2" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.5" d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z" fill="#71CBFE" />
                <path d="M20 12H22C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12Z" fill="#13A9FD" />
              </svg>
            </>}
            {getText("update")}
          </button>
        </div>
      </>}
    </>
  );
}

export default Profile;
