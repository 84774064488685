import { collection, doc, getDoc, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import Balance from "./Balance";
import Head from "./Head";
import { BLOCKCHAIN_SCAN_URL, USDT_DECIMAL } from "../constants";

const Deposit = () => {
  const [user] = useAuthState(auth);
  const [address, setAddress] = useState();
  const [copied, setCopied] = useState(false);
  const [deposits, setDeposits] = useState([]);

  useEffect(() => {
    const userRef = doc(db, "users", user.uid);
    getDoc(userRef).then((docSnap) => {
      if (docSnap.exists()) {
        const userData = docSnap.data();
        setAddress(userData.address);
      } else {
        console.log("No such document!");
      }
    });

    const q = query(
      collection(db, "deposits"),
      where("user", "==", userRef),
      orderBy("blockNumber", "desc"),
    );

    return onSnapshot(q, (snapshot) => {
      const docsData = [];

      snapshot.forEach((doc) => {
        docsData.push({
          id: doc.id,
          ...doc.data()
        });
      });

      setDeposits(docsData);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const copy = () => {
    navigator.clipboard.writeText(address);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  }

  return (
    <>
      <Head>Цэнэглэх</Head>

      {address ? <>
        <img className="m-auto" alt="address" width="164" src={`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${address}&choe=UTF-8`} />
      </> : <>
        <svg className="animate-spin m-auto" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.5" d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z" fill="#71CBFE" />
          <path d="M20 12H22C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12Z" fill="#13A9FD" />
        </svg>
      </>}

      <div className="p-4 mt-6 rounded-lg bg-[#FFF6E6] text-[#6D3B00] text-xs font-medium">
        <svg className="inline mr-2 align-middle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_214_1003)">
            <path d="M7.99992 14.6667C11.6818 14.6667 14.6666 11.6819 14.6666 8.00001C14.6666 4.31811 11.6818 1.33334 7.99992 1.33334C4.31802 1.33334 1.33325 4.31811 1.33325 8.00001C1.33325 11.6819 4.31802 14.6667 7.99992 14.6667Z" stroke="#6D3B00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 5.33334V8.00001" stroke="#6D3B00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 10.6667H8.0075" stroke="#6D3B00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_214_1003">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
        Анхааруулга<br />
        <p className="mt-2 text-[#99764D] font-normal">
          Зөвхөн Ethereum сүлжээн дээрх ERC-20 USDT-г дэмжинэ.<br />
          Хамгийн багадаа 10 USDT орлого хүлээн авна.<br />
          Бусад тохиолдолд таны хөрөнгө алдагдана!!!
        </p>
      </div>

      <div className="mt-4 font-bold text-[#373F5E]">Хаяг</div>
      <div className="p-4 mt-2 bg-white rounded-lg drop-shadow-[0_8px_24px_rgba(5,102,205,0.16)] text-[#5F657E] leading-4 text-sm">
        <div className="flex">
          <div className="flex-1 truncate">
            {address ? <span>{address}</span> : <>
              <svg className="animate-spin m-auto" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.5" d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z" fill="#71CBFE" />
                <path d="M20 12H22C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12Z" fill="#13A9FD" />
              </svg>
            </>}
          </div>
          <div className={`flex-none ${copied && "bg-green-500 text-white rounded p-[4px] px-[10px] -my-1"}`}>
            {copied ? <>
              Хуулагдлаа
            </> : <>
              <svg className="-my-1 ml-4 cursor-pointer" onClick={copy} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="4" fill="#EBECEF" />
                <path d="M18 17.5L18 8.49374C18 8.36114 17.9473 8.23396 17.8536 8.14019C17.7598 8.04642 17.6326 7.99374 17.5 7.99374C17.3674 7.99374 17.2402 8.04642 17.1464 8.14019C17.0527 8.23396 17 8.36114 17 8.49374L17 17L8.5 17C8.36739 17 8.24021 17.0527 8.14645 17.1464C8.05268 17.2402 8 17.3674 8 17.5C8 17.6326 8.05268 17.7598 8.14645 17.8535C8.24021 17.9473 8.36739 18 8.5 18L17.5 18C17.6326 18 17.7598 17.9473 17.8536 17.8535C17.9473 17.7598 18 17.6326 18 17.5Z" fill="#373F5E" />
                <path d="M16 15.5L16 6.49374C16 6.36114 15.9473 6.23396 15.8536 6.14019C15.7598 6.04642 15.6326 5.99374 15.5 5.99374L6.5 5.99374C6.36739 5.99374 6.24022 6.04642 6.14645 6.14019C6.05268 6.23396 6 6.36114 6 6.49374L6 15.5C6 15.6326 6.05268 15.7598 6.14645 15.8535C6.24021 15.9473 6.36739 16 6.5 16L15.5 16C15.6326 16 15.7598 15.9473 15.8536 15.8535C15.9473 15.7598 16 15.6326 16 15.5ZM7 15L7 6.99374L15 6.99374L15 15L7 15Z" fill="#373F5E" />
              </svg>
            </>}
          </div>
        </div>
      </div>

      <Balance />

      {!!deposits.length && <>
        <div className="mt-4 font-bold text-[#373F5E]">Орлогын түүх</div>
      </>}

      {deposits.map((deposit) => <div key={deposit.id} className="p-4 mt-2 bg-white rounded-lg drop-shadow-[0_8px_24px_rgba(5,102,205,0.16)] text-[#5F657E] leading-4 text-sm">
        <div>Hash: <a className="underline" href={`${BLOCKCHAIN_SCAN_URL}/tx/${deposit.hash}`} target="_blank" rel="noreferrer">{deposit.hash}</a></div>
        <div>From: {deposit.from}</div>
        <div>Value: {deposit.value / 10**USDT_DECIMAL} USDT</div>
        <div>Status: {deposit.status}</div>
      </div>)}
      <br />
    </>
  );
}

export default Deposit;
