import { useContext } from "react";
import Head from "./Head";
import TakeComponent from "./TakeComponent";
import { Context } from "../App";

// TODO:
// Change currency between crypto and fiat
const Take = () => {
  const {getText} = useContext(Context);

  return (
    <>
      <Head>{getText("take")}</Head>

      <p className="my-5 text-[#373F5E]">Buy/Sell crypto from ready to take market orders</p>

      <TakeComponent />
    </>
  );
}

export default Take;