import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase';
import { useContext, useEffect, useState } from "react";
import { Context } from "../App";
import { doc, getDoc } from "firebase/firestore";

const Navbar = () => {
  const [user] = useAuthState(auth);
  const [menuCollapsed, setMenuCollapsed] = useState(true);
  const [langMenuCollapsed, setLangMenuCollapsed] = useState(true);
  // const [userTypeMenuCollapsed, setUserTypeMenuCollapsed] = useState(true);
  const [isMerchant, setIsMerchant] = useState(false);
  const location = useLocation();
  const {lang, setLang, langs, getText, userType} = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      getDoc(doc(db, "users", user.uid)).then((userDoc) => {
        if (userDoc.exists()) {
          setIsMerchant(userDoc.data().isMerchant);
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    setLangMenuCollapsed(true);
  }, [lang]);

  useEffect(() => {
    setMenuCollapsed(true);
  }, [location]);

  const signout = () => {
    if (window.confirm("Та гарахдаа итгэлтэй байна уу?")) {
      auth.signOut().then(() => {
        navigate("/");
      });
    }
  }

  const NavItem = ({children, path, active}) => {
    return (
      <>
        <li>
          <Link className={`flex justify-between items-center ${(active || location.pathname === path) && "font-light"} whitespace-nowrap`} to={path}>
            {children}
            <svg className="lg:hidden" width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 14L7 7.5L0.999999 1" stroke="#E7F6FF" strokeWidth="2" />
            </svg>
          </Link>
        </li>
      </>
    )
  }

  return (
    <nav className="bg-gradient-radial-top from-[#0566CD] to-[#050F36] text-white font-medium">
      <div className="container mx-auto max-w-[1140px] px-4 py-10 flex flex-wrap justify-between items-center">
        <Link className="relative z-50 font-bold text-2xl basis-1/2 lg:basis-auto lg:text-4xl" to="/">
          <img width="100px" src="/logo.svg" alt="logo" />
        </Link>
        {location.pathname !== "/landing" && <>
          <div className="relative z-50 block basis-1/2 lg:basis-auto lg:hidden">
            <button onClick={() => setMenuCollapsed(!menuCollapsed)} className="flex float-right">
              <svg className={menuCollapsed ? "" : "hidden"} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.80005 17.6H27.2V14.4H4.80005V17.6ZM4.80005 25.6H27.2V22.4H4.80005V25.6ZM4.80005 6.39999V9.59999H27.2V6.39999H4.80005Z" fill="#E7F6FF" />
              </svg>
              <svg className={menuCollapsed ? "hidden" : ""} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.18 6L16 13.18L8.82 6L6 8.82L13.18 16L6 23.18L8.82 26L16 18.82L23.18 26L26 23.18L18.82 16L26 8.82L23.18 6Z" fill="#E7F6FF" />
              </svg>
            </button>
          </div>
        </>}
        <div className={`absolute lg:static w-full lg:w-auto p-[16px] lg:p-0 left-0 lg:left-auto top-[80px] lg:top-auto basis-full lg:basis-auto lg:flex ${menuCollapsed ? "hidden" : ""} lg:space-x-3 items-center z-40`}>
          <div className="fixed lg:hidden backdrop-blur w-screen h-screen left-0 top-0 z-30"></div>
          <div className="absolute lg:hidden mx-[calc(-50vw+50%)] left-0 max-w-[100vw] w-screen h-full bg-gradient-radial-next from-[#0566CD] to-[#050F36] z-30"></div>
          <ul className="relative flex-row flex-1 space-y-10 lg:space-y-0 lg:inline-flex mt-20 lg:mt-0 lg:items-center lg:space-x-3 z-30">
            <NavItem path="/">{getText("home")}</NavItem>
              {userType === "take" && <>
                <NavItem path="/take">{getText("take")}</NavItem>
              </>}
            {user && <>
              {isMerchant && <>
                <NavItem path="/order/create">{getText("create_ad")}</NavItem>
                <NavItem path="/order">{getText("ads")}</NavItem>
              </>}
              <NavItem path="/escrow" active={location.pathname.match(/^\/escrow/g)}>{getText("orders")}</NavItem>
              <NavItem path="/profile">{getText("profile")}</NavItem>
            </>}
          </ul>
          <div className="relative hidden fflex justify-between space-x-3 items-center border border-[#535F8B] mt-10 lg:mt-0 py-5 px-6 lg:p-2 rounded-lg w-full lg:w-20 text-left z-40 cursor-pointer" onMouseLeave={() => setLangMenuCollapsed(true)} onClick={() => setLangMenuCollapsed(!langMenuCollapsed)}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM16.92 6H13.97C13.657 4.76146 13.1936 3.5659 12.59 2.44C14.4141 3.068 15.9512 4.33172 16.92 6ZM10 2.04C10.83 3.24 11.48 4.57 11.91 6H8.09C8.52 4.57 9.17 3.24 10 2.04ZM2.26 12C2.1 11.36 2 10.69 2 10C2 9.31 2.1 8.64 2.26 8H5.64C5.56 8.66 5.5 9.32 5.5 10C5.5 10.68 5.56 11.34 5.64 12H2.26ZM3.08 14H6.03C6.35 15.25 6.81 16.45 7.41 17.56C5.58397 16.9354 4.04583 15.6708 3.08 14ZM6.03 6H3.08C4.04583 4.32918 5.58397 3.06457 7.41 2.44C6.80643 3.5659 6.34298 4.76146 6.03 6ZM10 17.96C9.17 16.76 8.52 15.43 8.09 14H11.91C11.48 15.43 10.83 16.76 10 17.96ZM12.34 12H7.66C7.57 11.34 7.5 10.68 7.5 10C7.5 9.32 7.57 8.65 7.66 8H12.34C12.43 8.65 12.5 9.32 12.5 10C12.5 10.68 12.43 11.34 12.34 12ZM12.59 17.56C13.19 16.45 13.65 15.25 13.97 14H16.92C15.9512 15.6683 14.4141 16.932 12.59 17.56ZM14.36 12C14.44 11.34 14.5 10.68 14.5 10C14.5 9.32 14.44 8.66 14.36 8H17.74C17.9 8.64 18 9.31 18 10C18 10.69 17.9 11.36 17.74 12H14.36Z" fill="#7D86A7" />
            </svg>
            <div className="grow">{lang.toUpperCase()}</div>
            <svg className={`${!langMenuCollapsed && "rotate-180"} lg:hidden`} width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.80295 9.9183C7.40314 10.4569 6.59686 10.4569 6.19704 9.9183L0.390523 2.09604C-0.0992862 1.4362 0.371706 0.500001 1.19348 0.500001L12.8065 0.500002C13.6283 0.500002 14.0993 1.4362 13.6095 2.09604L7.80295 9.9183Z" fill="#535F8B" />
            </svg>
            <div className={`flex flex-col -left-3 top-16 lg:top-10 text-xs w-full absolute ${langMenuCollapsed && "hidden"} text-black z-50`}>
              {langs.map((l) => <button key={l} className={`p-2 ${l === lang ? " bg-slate-300" : "bg-white"}`} onClick={() => setLang(l)}>{l.toUpperCase()}</button>)}
            </div>
          </div>
          {/* {userType && <>
            <div className="relative flex justify-between space-x-3 items-center border border-[#535F8B] mt-10 lg:mt-0 py-5 px-6 lg:p-2 rounded-lg w-full text-left z-30 cursor-pointer" onMouseLeave={() => setUserTypeMenuCollapsed(true)} onClick={() => setUserTypeMenuCollapsed(!userTypeMenuCollapsed)}>
              <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.9231 11.1111C20.9231 16.7081 16.4504 21.2222 10.9615 21.2222C5.47267 21.2222 1 16.7081 1 11.1111C1 5.51408 5.47267 1 10.9615 1C16.4504 1 20.9231 5.51408 20.9231 11.1111Z" stroke="#7D86A7" strokeWidth="2" />
                <path d="M9.44943 14.1544L10.9615 13.5321L12.4737 14.1544C13.5934 14.6152 14.5072 15.5841 14.9458 16.8249C13.8786 17.7173 12.4879 18.2592 10.9615 18.2592C9.43508 18.2592 8.04385 17.7172 6.97725 16.825C7.41582 15.5842 8.32962 14.6152 9.44943 14.1544ZM10.9615 13.3134L9.6337 12.4044C9.04128 11.9989 8.66894 11.3412 8.66894 10.6091C8.66894 9.41923 9.66874 8.40735 10.9615 8.40735C12.2543 8.40735 13.2541 9.41923 13.2541 10.6091C13.2541 11.3412 12.8818 11.9989 12.2894 12.4044L10.9615 13.3134Z" stroke="#7D86A7" strokeWidth="2" />
              </svg>
              <div className="grow">{userType?.toUpperCase()}</div>
              <svg className={`${!userTypeMenuCollapsed && "rotate-180"} lg:hidden`} width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.80295 9.9183C7.40314 10.4569 6.59686 10.4569 6.19704 9.9183L0.390523 2.09604C-0.0992862 1.4362 0.371706 0.500001 1.19348 0.500001L12.8065 0.500002C13.6283 0.500002 14.0993 1.4362 13.6095 2.09604L7.80295 9.9183Z" fill="#535F8B" />
              </svg>
            </div>
          </>} */}
          {user ? <>
            <div className="relative items-center flex mt-16 lg:mt-0 z-30 whitespace-nowrap cursor-pointer" onClick={signout}>
              <svg className="mr-2" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M15.9842 4C9.37563 4 4 9.37563 4 15.9842C4 22.6244 9.37563 28 15.9842 28C22.6244 28 28 22.6244 28 15.9842C28 9.37563 22.6244 4 15.9842 4ZM22.0552 10.4187H15.8259V11.5255H20.917V20.4426H15.8259V21.5809H22.0552V10.4187ZM9.94476 16.0153V15.9837C9.94476 15.9204 9.97633 15.8573 10.0081 15.8257L12.6643 13.1379C12.7592 13.043 12.9173 13.043 13.0122 13.1379L13.4232 13.5489C13.5181 13.6438 13.5181 13.8019 13.4232 13.8968L11.8737 15.4779H18.609C18.7355 15.4779 18.862 15.5728 18.862 15.6992V16.3C18.862 16.4265 18.7355 16.5214 18.609 16.5214H11.8737L13.4232 18.0709C13.5181 18.1658 13.5181 18.3238 13.4232 18.4187L13.0122 18.8297C12.9172 18.9246 12.7592 18.9246 12.6643 18.8297L10.0081 16.1735C9.97652 16.1102 9.94476 16.0786 9.94476 16.0155V16.0153Z" fill="#7D86A7" />
              </svg>
              {getText("logout")}
            </div>
          </> : <>
            <div className="relative pt-12 lg:pt-0 lg:-mt-1 z-30 whitespace-nowrap">
              <Link className="border align-middle border-[#13A9FD] top-10 py-5 px-6 rounded-lg text-[#13A9FD] text-center lg:mt-0 lg:py-2.5" to="/auth">
                {getText("login")}
              </Link>
            </div>
          </>}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
