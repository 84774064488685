import { useContext, useEffect, useState } from "react";
import { collection, doc, onSnapshot, query, where, and, or } from "firebase/firestore";
import { auth, db } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import Head from "../Head";
import { Context } from "../../App";
import { USDT_DECIMAL } from "../../constants";

const Escrows = () => {
  const [escrows, setEscrows] = useState([]);
  const [buyerEscrows, setBuyerEscrows] = useState([]);
  const [sellerEscrows, setSellerEscrows] = useState([]);
  const [user] = useAuthState(auth);
  const {getText} = useContext(Context);
  const [complete, setComplete] = useState(false);
  const [completeFilter, setCompleteFilter] = useState("");

  const getData = (snapshot) => {
      const docsData = [];

      snapshot.forEach((doc) => {
        docsData.push({
          id: doc.id,
          ...doc.data()
        });
      });

      return docsData;
  }

  useEffect(() => {
    const userRef = doc(db, "users", user.uid);

    let takerQuery;
    let makerQuery;

    if (complete) {
      if (completeFilter === "succeed") {
        takerQuery = query(collection(db, "escrows"), where("taker", "==", userRef), where("completed", "==", true));
        makerQuery = query(collection(db, "escrows"), where("maker", "==", userRef), where("completed", "==", true));
      } else if (completeFilter === "paidback") {
        takerQuery = query(collection(db, "escrows"), where("taker", "==", userRef), where("sent", "==", false), where("timedOut", "==", true));
        makerQuery = query(collection(db, "escrows"), where("maker", "==", userRef), where("sent", "==", false), where("timedOut", "==", true));
      } else if (completeFilter === "failed") {
        takerQuery = query(collection(db, "escrows"), where("taker", "==", userRef), where("sent", "==", true), where("timedOut", "==", true));
        makerQuery = query(collection(db, "escrows"), where("maker", "==", userRef), where("sent", "==", true), where("timedOut", "==", true));
      } else {
        takerQuery = query(collection(db, "escrows"), and(where("taker", "==", userRef), or(where("completed", "==", true), where("timedOut", "==", true))));
        makerQuery = query(collection(db, "escrows"), and(where("maker", "==", userRef), or(where("completed", "==", true), where("timedOut", "==", true))));
      }
    } else {
      takerQuery = query(collection(db, "escrows"), where("taker", "==", userRef), where("completed", "==", false), where("timedOut", "==", false));
      makerQuery = query(collection(db, "escrows"), where("maker", "==", userRef), where("completed", "==", false), where("timedOut", "==", false));
    }

    const buyerEscrowsUnsubscribe = onSnapshot(takerQuery, (snapshot) => {
      setBuyerEscrows(getData(snapshot));
    });

    const sellerEscrowsUnsubscribe = onSnapshot(makerQuery, (snapshot) => {
      setSellerEscrows(getData(snapshot));
    });

    return () => {
      buyerEscrowsUnsubscribe();
      sellerEscrowsUnsubscribe();
    }
  }, [complete, completeFilter, user]);

  useEffect(() => {
    setEscrows([
      ...sellerEscrows,
      ...buyerEscrows.filter((escrow) => !sellerEscrows.find((esc) => escrow.id === esc.id)),
    ]);
  }, [sellerEscrows, buyerEscrows]);

  return (
    <>
      <Head>{getText("orders")}</Head>

      <div className="flex gap-2 mb-2">
        <div className={`${!complete ? "bg-[#13A9FD] text-white" : "bg-[#E6F0FA] text-[#9BC2EB]"} py-4 px-6 rounded-full w-full flex-1 text-center cursor-pointer`} onClick={() => setComplete(false)}>
          ИДЭВХИТЭЙ
        </div>
        <div className={`${complete ? "bg-[#13A9FD] text-white" : "bg-[#E6F0FA] text-[#9BC2EB]"} py-4 px-6 rounded-full w-full flex-1 text-center cursor-pointer`} onClick={() => setComplete(true)}>
          ДУУССАН
        </div>
      </div>
      {complete && <>
        <div className="flex gap-2 mb-10">
          <div className={`${completeFilter === "succeed" ? "bg-[#13A9FD] text-white" : "bg-[#E6F0FA] text-[#9BC2EB]"} py-4 px-6 rounded-full w-full flex-1 text-center cursor-pointer`} onClick={() => setCompleteFilter(completeFilter === "succeed" ? "" : "succeed")}>
            АМЖИЛТТАЙ
          </div>
          <div className={`${completeFilter === "paidback" ? "bg-[#13A9FD] text-white" : "bg-[#E6F0FA] text-[#9BC2EB]"} py-4 px-6 rounded-full w-full flex-1 text-center cursor-pointer`} onClick={() => setCompleteFilter(completeFilter === "paidback" ? "" : "paidback")}>
            БУЦААГДСАН
          </div>
          <div className={`${completeFilter === "failed" ? "bg-[#13A9FD] text-white" : "bg-[#E6F0FA] text-[#9BC2EB]"} py-4 px-6 rounded-full w-full flex-1 text-center cursor-pointer`} onClick={() => setCompleteFilter(completeFilter === "failed" ? "" : "failed")}>
            ТҮГЖИГДСЭН
          </div>
        </div>
      </>}

      {escrows.map((escrow) => (
        <Link key={escrow.id} className="block mb-6" to={"/escrow/" + escrow.id}>
          <div className="flex items-end font-bold">
            <div className="basis-1/2">{escrow.type === "buy" ? "Buy" : "Sell"} USDT</div>
            <div className="basis-1/2 text-xs font-normal text-[#5F657E]">{escrow.id.slice(0, 6)}</div>
          </div>
          <div className="px-4 py-3 mt-2 bg-white rounded-lg drop-shadow-[0_8px_24px_rgba(5,102,205,0.16)] text-[#5F657E] leading-4 text-xs">
            <div className="flex border-[#E7F6FF] border-solid border-b pb-3">
              <div className="basis-1/2">{escrow.amount/10**USDT_DECIMAL} USDT</div>
              <div className="basis-1/2">{(escrow.amount*escrow.price)/10**USDT_DECIMAL} {escrow.currency}</div>
            </div>
            <div className="flex border-[#E7F6FF] border-b-solid border-b-1 my-3">
              <div className="basis-1/2">
                Unit price
              </div>
              <div className="basis-1/2">{(escrow.price).toFixed(2)} {escrow.currency}/USDT</div>
            </div>
            <div className="flex items-center">
              <div className="flex-1 pr-2">
                Sent
              </div>
              <div className="flex-1 pr-2">
                {escrow.sent ? <>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 7L9.75152 16L5 11.848" stroke="#00C6C4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </> : escrow.timedOut ? <>
                  <div className="text-red-500 text-[25px]">&times;</div>
                </> : <>
                  <svg className="animate-spin" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.5" d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z" fill="#71CBFE" />
                    <path d="M20 12H22C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12Z" fill="#13A9FD" />
                  </svg>
                </>}
              </div>
              <div className="flex-1 pr-2">
                Received
              </div>
              <div className="flex-1 pr-2">
                {escrow.received ? <>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 7L9.75152 16L5 11.848" stroke="#00C6C4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </> : escrow.timedOut ? <>
                  <div className="text-red-500 text-[25px]">&times;</div>
                </> : <>
                  <svg className="animate-spin" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.5" d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z" fill="#71CBFE" />
                    <path d="M20 12H22C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12Z" fill="#13A9FD" />
                  </svg>
                </>}
              </div>
            </div>
          </div>
        </Link>
      ))}
    </>
  );
}

export default Escrows;