import { doc, onSnapshot } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Context } from "../../App";
import { auth, db } from "../../firebase";
import Head from "../Head";
import { USDT_DECIMAL } from "../../constants";

// TODO:
// Countdown timer
// Appeal
const Escrow = () => {
  const [user] = useAuthState(auth);
  const {escrowId} = useParams();
  const [escrow, setEscrow] = useState({});
  const [sending, setSending] = useState(false);
  const [receiving, setReceiving] = useState(false);
  const [timestamp, setTimestamp] = useState(0);
  const [countdown, setCountdown] = useState(0);
  const [intrvl, setIntrvl] = useState();
  const [error, setError] = useState();
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const functions = getFunctions();
  const {getText} = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    return onSnapshot(doc(db, "escrows", escrowId), (escrowSnap) => {
      setError("");
      if (!escrowSnap.exists()) return setError("No such document!");
      setEscrow(escrowSnap.data());
    });
  }, [escrowId]);

  const updateCountdown = () => {
    const diff = Math.max(900000 - (Date.now() - (new Date(timestamp*1000))), 0);
    setCountdown(`${String(~~(diff/60000)).padStart(2, '0')}:${String(~~((diff%60000)/1000)).padStart(2, '0')}`);
  }

  useEffect(() => {
    if (timestamp) {
      clearInterval(intrvl);
      setIntrvl(setInterval(updateCountdown, 1000));
    }

    return () => {
      clearInterval(intrvl);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamp]);

  useEffect(() => {
    if (escrow?.sent) {
      setTimestamp(escrow?.sentAt?.seconds);
    } else {
      setTimestamp(escrow?.createdAt?.seconds);
    }
  }, [escrow]);

  const sent = async () => {
    if (!sending) {
      setError("");
      setSending(true);
      const sentFunction = httpsCallable(functions, "sent");
      await sentFunction({
        escrowId
      }).then((result) => {
        if (!result.data) return setError(JSON.stringify(result));
      }).catch((error) => {
        setError(error.message);
      }).finally(() => {
        setSending(false);
      });
    }
  }

  const received = async () => {
    if (window.confirm("Та хүлээн авсандаа итгэлтэй байна уу?")) {
      if (!receiving) {
        setError("");;
        setReceiving(true);
        const receivedFunction = httpsCallable(functions, "received");
        await receivedFunction({
          escrowId
        }).then((result) => {
          if (!result.data) return setError(JSON.stringify(result));
          navigate("/profile");
        }).catch((error) => {
          setError(error.message);
        }).finally(() => {
          setReceiving(false);
        });
      }
    }
  }

  return (
    <>
      <Head link="/escrow">{getText("order")}</Head>

      <div className="text-[#373F5E] mt-6">Түгжигдсэн</div>
      <div className="flex items-center relative border border-[#0566CD] rounded-lg mt-2">
        <div className="pl-4">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 12C20 10.897 19.103 10 18 10H17V7C17 4.243 14.757 2 12 2C9.243 2 7 4.243 7 7V10H6C4.897 10 4 10.897 4 12V20C4 21.103 4.897 22 6 22H18C19.103 22 20 21.103 20 20V12ZM9 7C9 5.346 10.346 4 12 4C13.654 4 15 5.346 15 7V10H9V7Z" fill="#FCB057" />
            <path fillRule="evenodd" clipRule="evenodd" d="M13 17.7324C13.5978 17.3866 14 16.7403 14 16C14 14.8954 13.1046 14 12 14C10.8954 14 10 14.8954 10 16C10 16.7403 10.4022 17.3866 11 17.7324V20H13V17.7324Z" fill="white" />
          </svg>
        </div>
        <div className="grow">
          <input className="input border-0 py-0 pl-2 bg-white" placeholder="Amount" type="number" defaultValue={escrow?.amount/10**USDT_DECIMAL || ""} disabled />
        </div>
        <div className="h-[66px] flex items-center">
          <div className="border-[#0566CD] border-r h-10"></div>
        </div>
        <div className="input py-0 border-0 !w-[80px] text-[#0566CD]">USDT</div>
      </div>

      <div className="flex mt-10">
        <div>
          <div className="rounded-lg bg-[#E7F6FF] w-[96px] p-[6px]">
            <code className="text-sm font-bold text-[#0566CD] pl-2">
              {!escrow?.sent && countdown}
            </code>

            {escrow?.sent ? <>
              <svg className="inline float-right" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 7L9.75152 16L5 11.848" stroke="#00C6C4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </> : <>
              <svg className="inline float-right animate-spin" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.5" d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z" fill="#71CBFE" />
                <path d="M20 12H22C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12Z" fill="#13A9FD" />
              </svg>
            </>}

            <div className="p-[10px]">
              <svg width="64" height="45" viewBox="0 0 64 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.75 15V22.5019H5.625V33.75H3.75V39.375H33.75L39.375 39.3769V39.375H41.25V33.75H39.375V22.5019H41.25V15L22.5 3.75L3.75 15ZM11.25 33.75V22.5019H15V33.75H11.25ZM20.625 33.75V22.5019H24.375V33.75H20.625ZM33.75 33.75H30V22.5019H33.75V33.75ZM26.25 15C26.2499 15.4926 26.1527 15.9803 25.9641 16.4354C25.7755 16.8904 25.4991 17.3038 25.1507 17.652C24.8023 18.0003 24.3888 18.2764 23.9336 18.4648C23.4785 18.6532 22.9907 18.7501 22.4981 18.75C22.0055 18.7499 21.5178 18.6527 21.0628 18.4641C20.6077 18.2755 20.1943 17.9991 19.8461 17.6507C19.4979 17.3023 19.2217 16.8888 19.0333 16.4336C18.8449 15.9785 18.748 15.4907 18.7481 14.9981C18.7484 14.0033 19.1438 13.0493 19.8474 12.3461C20.551 11.6428 21.5052 11.2479 22.5 11.2481C23.4948 11.2484 24.4488 11.6438 25.152 12.3474C25.8553 13.051 26.2502 14.0052 26.25 15Z" fill="#13A9FD" />
                <g clipPath="url(#clip0_322_814)">
                  <path d="M22.5 10C20.015 10 18 12.015 18 14.5C18 16.985 20.015 19 22.5 19C24.985 19 27 16.985 27 14.5C27 12.015 24.985 10 22.5 10ZM22.724 16.6817L22.726 17.0001C22.726 17.0443 22.6898 17.0815 22.6456 17.0815H22.3604C22.3162 17.0815 22.28 17.0453 22.28 17.0011V16.6857C21.3881 16.6194 20.9682 16.1112 20.923 15.5587C20.919 15.5115 20.9561 15.4713 21.0033 15.4713H21.4674C21.5066 15.4713 21.5407 15.4994 21.5468 15.5376C21.598 15.856 21.8461 16.0941 22.2911 16.1533V14.72L22.043 14.6567C21.5176 14.5311 21.0174 14.2037 21.0174 13.5247C21.0174 12.7924 21.5739 12.3987 22.285 12.3294V11.9979C22.285 11.9537 22.3212 11.9175 22.3654 11.9175H22.6477C22.6919 11.9175 22.728 11.9537 22.728 11.9979V12.3263C23.4161 12.3956 23.9324 12.7974 23.9926 13.4232C23.9977 13.4704 23.9605 13.5116 23.9123 13.5116H23.4613C23.4211 13.5116 23.3869 13.4815 23.3819 13.4423C23.3417 13.149 23.1067 12.9099 22.724 12.8577V14.2067L22.9791 14.266C23.63 14.4267 24.073 14.7381 24.073 15.4352C24.073 16.1915 23.5105 16.6134 22.724 16.6817ZM21.6422 13.4785C21.6422 13.7336 21.7999 13.9315 22.1394 14.054C22.1866 14.0731 22.2338 14.0882 22.2901 14.1042V12.8587C21.9194 12.9059 21.6422 13.1138 21.6422 13.4785ZM22.8124 14.8365C22.7843 14.8305 22.7561 14.8234 22.724 14.8144V16.1574C23.1519 16.1192 23.4472 15.8842 23.4472 15.4904C23.4472 15.182 23.2875 14.9811 22.8124 14.8365Z" fill="#0566CD" />
                </g>
                <path d="M56.5049 34.2879L56.5049 31.4648L49 31.4648L49 24.5373L56.5049 24.5373L56.5049 21.7142C56.5049 20.971 56.9415 20.7756 57.4755 21.281L63.5981 27.0812C64.134 27.5848 64.134 28.413 63.5981 28.9202L57.4755 34.7186C56.9409 35.2246 56.5049 35.0286 56.5049 34.2878L56.5049 34.2879Z" fill="#F46C6C" />
                <defs>
                  <clipPath id="clip0_322_814">
                    <rect width="9" height="9" fill="white" transform="translate(18 10)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div className="pt-2 text-center text-[#5F657E] text-sm">
            {escrow?.currency}<br />
            {escrow?.sent ? " явсан" : " явуулж байна "}
          </div>
        </div>

        <div className="border-0 border-dashed border-t-2 border-[#B8E5FE] grow h-0 text-center mt-14"></div>

        <div>
          <div className="rounded-lg bg-[#E7F6FF] w-[96px] p-[6px]">
            <code className="text-sm font-bold text-[#0566CD] pl-2">
              {(escrow?.sent && !escrow?.received) ? countdown : "15:00"}
            </code>

            {escrow?.received ? <>
              <svg className="inline float-right" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 7L9.75152 16L5 11.848" stroke="#00C6C4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </> : <>
              <svg className="inline float-right animate-spin" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.5" d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z" fill="#71CBFE" />
                <path d="M20 12H22C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12Z" fill="#13A9FD" />
              </svg>
            </>}

            <div className="p-[10px]">
              <svg width="64" height="45" viewBox="0 0 64 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.75 15V22.5019H24.625V33.75H22.75V39.375H52.75L58.375 39.3769V39.375H60.25V33.75H58.375V22.5019H60.25V15L41.5 3.75L22.75 15ZM30.25 33.75V22.5019H34V33.75H30.25ZM39.625 33.75V22.5019H43.375V33.75H39.625ZM52.75 33.75H49V22.5019H52.75V33.75ZM45.25 15C45.2499 15.4926 45.1527 15.9803 44.9641 16.4354C44.7755 16.8904 44.4991 17.3038 44.1507 17.652C43.8023 18.0003 43.3888 18.2764 42.9336 18.4648C42.4785 18.6532 41.9907 18.7501 41.4981 18.75C41.0055 18.7499 40.5178 18.6527 40.0628 18.4641C39.6077 18.2755 39.1943 17.9991 38.8461 17.6507C38.4979 17.3023 38.2217 16.8888 38.0333 16.4336C37.8449 15.9785 37.748 15.4907 37.7481 14.9981C37.7484 14.0033 38.1438 13.0493 38.8474 12.3461C39.551 11.6428 40.5052 11.2479 41.5 11.2481C42.4948 11.2484 43.4488 11.6438 44.152 12.3474C44.8553 13.051 45.2502 14.0052 45.25 15Z" fill="#13A9FD" />
                <g clipPath="url(#clip0_322_822)">
                  <path d="M41.5 10C39.015 10 37 12.015 37 14.5C37 16.985 39.015 19 41.5 19C43.985 19 46 16.985 46 14.5C46 12.015 43.985 10 41.5 10ZM41.724 16.6817L41.726 17.0001C41.726 17.0443 41.6898 17.0815 41.6456 17.0815H41.3604C41.3162 17.0815 41.28 17.0453 41.28 17.0011V16.6857C40.3881 16.6194 39.9682 16.1112 39.923 15.5587C39.919 15.5115 39.9561 15.4713 40.0033 15.4713H40.4674C40.5066 15.4713 40.5407 15.4994 40.5468 15.5376C40.598 15.856 40.8461 16.0941 41.2911 16.1533V14.72L41.043 14.6567C40.5176 14.5311 40.0174 14.2037 40.0174 13.5247C40.0174 12.7924 40.5739 12.3987 41.285 12.3294V11.9979C41.285 11.9537 41.3212 11.9175 41.3654 11.9175H41.6477C41.6919 11.9175 41.728 11.9537 41.728 11.9979V12.3263C42.4161 12.3956 42.9324 12.7974 42.9926 13.4232C42.9977 13.4704 42.9605 13.5116 42.9123 13.5116H42.4613C42.4211 13.5116 42.3869 13.4815 42.3819 13.4423C42.3417 13.149 42.1067 12.9099 41.724 12.8577V14.2067L41.9791 14.266C42.63 14.4267 43.073 14.7381 43.073 15.4352C43.073 16.1915 42.5105 16.6134 41.724 16.6817ZM40.6422 13.4785C40.6422 13.7336 40.7999 13.9315 41.1394 14.054C41.1866 14.0731 41.2338 14.0882 41.2901 14.1042V12.8587C40.9194 12.9059 40.6422 13.1138 40.6422 13.4785ZM41.8124 14.8365C41.7843 14.8305 41.7561 14.8234 41.724 14.8144V16.1574C42.1519 16.1192 42.4472 15.8842 42.4472 15.4904C42.4472 15.182 42.2875 14.9811 41.8124 14.8365Z" fill="#0566CD" />
                </g>
                <path d="M7.50491 21.7121L7.50491 24.5351L1.24792e-07 24.5351L4.21817e-08 31.4627L7.50491 31.4627L7.50491 34.2858C7.50491 35.029 7.94152 35.2244 8.47554 34.719L14.5981 28.9187C15.134 28.4152 15.134 27.587 14.5981 27.0798L8.47554 21.2814C7.94093 20.7754 7.50491 20.9713 7.50491 21.7121L7.50491 21.7121Z" fill="#00C6C4" />
                <defs>
                  <clipPath id="clip0_322_822">
                    <rect width="9" height="9" fill="white" transform="translate(37 10)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div className="pt-2 text-center text-[#5F657E] text-sm">
            {escrow?.currency}<br />
            {escrow?.received ? " хүлээн авсан" : " хүлээж байна "}
          </div>
        </div>
      </div>

      {((escrow?.type === "buy" && escrow?.maker.id === user.uid) || (escrow?.type === "sell" && escrow?.taker.id === user.uid)) && <>
        <div className="text-sm text-[#0566CD] mt-10">Шилжүүлэх дүн: <b>{(escrow?.amount * escrow?.price)/10**USDT_DECIMAL}</b> {escrow?.currency}</div>

        <div className="mt-4 font-bold text-[#373F5E]">Шилжүүлэх данс:</div>
        <div className="grid grid-cols-2 gap-6 px-4 py-6 mt-2 bg-white rounded-lg drop-shadow-[0_8px_24px_rgba(5,102,205,0.16)] text-[#5F657E] leading-4 text-sm">
          <div className="flex basis-1/2">
            <div className="grow">
              Банк<br />
              <strong>{escrow?.bankInfo?.bank}</strong>
            </div>

            <div className="flex-none cursor-pointer" onClick={() => navigator.clipboard.writeText(escrow?.bankInfo?.bank)}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="4" fill="#EBECEF" />
                <path d="M18 17.5L18 8.49374C18 8.36114 17.9473 8.23396 17.8536 8.14019C17.7598 8.04642 17.6326 7.99374 17.5 7.99374C17.3674 7.99374 17.2402 8.04642 17.1464 8.14019C17.0527 8.23396 17 8.36114 17 8.49374L17 17L8.5 17C8.36739 17 8.24021 17.0527 8.14645 17.1464C8.05268 17.2402 8 17.3674 8 17.5C8 17.6326 8.05268 17.7598 8.14645 17.8535C8.24021 17.9473 8.36739 18 8.5 18L17.5 18C17.6326 18 17.7598 17.9473 17.8536 17.8535C17.9473 17.7598 18 17.6326 18 17.5Z" fill="#373F5E" />
                <path d="M16 15.5L16 6.49374C16 6.36114 15.9473 6.23396 15.8536 6.14019C15.7598 6.04642 15.6326 5.99374 15.5 5.99374L6.5 5.99374C6.36739 5.99374 6.24022 6.04642 6.14645 6.14019C6.05268 6.23396 6 6.36114 6 6.49374L6 15.5C6 15.6326 6.05268 15.7598 6.14645 15.8535C6.24021 15.9473 6.36739 16 6.5 16L15.5 16C15.6326 16 15.7598 15.9473 15.8536 15.8535C15.9473 15.7598 16 15.6326 16 15.5ZM7 15L7 6.99374L15 6.99374L15 15L7 15Z" fill="#373F5E" />
              </svg>
            </div>
          </div>
          <div className="flex basis-1/2">
            <div className="grow">
              Дансны нэр<br />
              <strong>{escrow?.bankInfo?.bankAccountName}</strong>
            </div>

            <div className="flex-none cursor-pointer" onClick={() => navigator.clipboard.writeText(escrow?.bankInfo?.bankAccountName)}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="4" fill="#EBECEF" />
                <path d="M18 17.5L18 8.49374C18 8.36114 17.9473 8.23396 17.8536 8.14019C17.7598 8.04642 17.6326 7.99374 17.5 7.99374C17.3674 7.99374 17.2402 8.04642 17.1464 8.14019C17.0527 8.23396 17 8.36114 17 8.49374L17 17L8.5 17C8.36739 17 8.24021 17.0527 8.14645 17.1464C8.05268 17.2402 8 17.3674 8 17.5C8 17.6326 8.05268 17.7598 8.14645 17.8535C8.24021 17.9473 8.36739 18 8.5 18L17.5 18C17.6326 18 17.7598 17.9473 17.8536 17.8535C17.9473 17.7598 18 17.6326 18 17.5Z" fill="#373F5E" />
                <path d="M16 15.5L16 6.49374C16 6.36114 15.9473 6.23396 15.8536 6.14019C15.7598 6.04642 15.6326 5.99374 15.5 5.99374L6.5 5.99374C6.36739 5.99374 6.24022 6.04642 6.14645 6.14019C6.05268 6.23396 6 6.36114 6 6.49374L6 15.5C6 15.6326 6.05268 15.7598 6.14645 15.8535C6.24021 15.9473 6.36739 16 6.5 16L15.5 16C15.6326 16 15.7598 15.9473 15.8536 15.8535C15.9473 15.7598 16 15.6326 16 15.5ZM7 15L7 6.99374L15 6.99374L15 15L7 15Z" fill="#373F5E" />
              </svg>
            </div>
          </div>
          <div className="flex basis-1/2">
            <div className="grow">
              Дансны дугаар<br />
              <strong>{escrow?.bankInfo?.bankAccountNumber}</strong>
            </div>

            <div className="flex-none cursor-pointer" onClick={() => navigator.clipboard.writeText(escrow?.bankInfo?.bankAccountNumber)}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="4" fill="#EBECEF" />
                <path d="M18 17.5L18 8.49374C18 8.36114 17.9473 8.23396 17.8536 8.14019C17.7598 8.04642 17.6326 7.99374 17.5 7.99374C17.3674 7.99374 17.2402 8.04642 17.1464 8.14019C17.0527 8.23396 17 8.36114 17 8.49374L17 17L8.5 17C8.36739 17 8.24021 17.0527 8.14645 17.1464C8.05268 17.2402 8 17.3674 8 17.5C8 17.6326 8.05268 17.7598 8.14645 17.8535C8.24021 17.9473 8.36739 18 8.5 18L17.5 18C17.6326 18 17.7598 17.9473 17.8536 17.8535C17.9473 17.7598 18 17.6326 18 17.5Z" fill="#373F5E" />
                <path d="M16 15.5L16 6.49374C16 6.36114 15.9473 6.23396 15.8536 6.14019C15.7598 6.04642 15.6326 5.99374 15.5 5.99374L6.5 5.99374C6.36739 5.99374 6.24022 6.04642 6.14645 6.14019C6.05268 6.23396 6 6.36114 6 6.49374L6 15.5C6 15.6326 6.05268 15.7598 6.14645 15.8535C6.24021 15.9473 6.36739 16 6.5 16L15.5 16C15.6326 16 15.7598 15.9473 15.8536 15.8535C15.9473 15.7598 16 15.6326 16 15.5ZM7 15L7 6.99374L15 6.99374L15 15L7 15Z" fill="#373F5E" />
              </svg>
            </div>
          </div>
          <div className="flex basis-1/2">
            <div className="grow">
              Гүйлгээний утга<br />
              <strong>{escrowId.slice(0, 6)}</strong>
            </div>

            <div className="flex-none cursor-pointer" onClick={() => navigator.clipboard.writeText(escrowId.slice(0, 6))}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="4" fill="#EBECEF" />
                <path d="M18 17.5L18 8.49374C18 8.36114 17.9473 8.23396 17.8536 8.14019C17.7598 8.04642 17.6326 7.99374 17.5 7.99374C17.3674 7.99374 17.2402 8.04642 17.1464 8.14019C17.0527 8.23396 17 8.36114 17 8.49374L17 17L8.5 17C8.36739 17 8.24021 17.0527 8.14645 17.1464C8.05268 17.2402 8 17.3674 8 17.5C8 17.6326 8.05268 17.7598 8.14645 17.8535C8.24021 17.9473 8.36739 18 8.5 18L17.5 18C17.6326 18 17.7598 17.9473 17.8536 17.8535C17.9473 17.7598 18 17.6326 18 17.5Z" fill="#373F5E" />
                <path d="M16 15.5L16 6.49374C16 6.36114 15.9473 6.23396 15.8536 6.14019C15.7598 6.04642 15.6326 5.99374 15.5 5.99374L6.5 5.99374C6.36739 5.99374 6.24022 6.04642 6.14645 6.14019C6.05268 6.23396 6 6.36114 6 6.49374L6 15.5C6 15.6326 6.05268 15.7598 6.14645 15.8535C6.24021 15.9473 6.36739 16 6.5 16L15.5 16C15.6326 16 15.7598 15.9473 15.8536 15.8535C15.9473 15.7598 16 15.6326 16 15.5ZM7 15L7 6.99374L15 6.99374L15 15L7 15Z" fill="#373F5E" />
              </svg>
            </div>
          </div>
        </div>
      </>}

      <div className="p-4 mt-6 rounded-lg bg-[#FFF6E6] text-[#6D3B00] text-xs font-medium">
        <svg className="inline mr-2 align-middle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_214_1003)">
            <path d="M7.99992 14.6667C11.6818 14.6667 14.6666 11.6819 14.6666 8.00001C14.6666 4.31811 11.6818 1.33334 7.99992 1.33334C4.31802 1.33334 1.33325 4.31811 1.33325 8.00001C1.33325 11.6819 4.31802 14.6667 7.99992 14.6667Z" stroke="#6D3B00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 5.33334V8.00001" stroke="#6D3B00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 10.6667H8.0075" stroke="#6D3B00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_214_1003">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
        Анхааруулга<br />
        <p className="mt-2 text-[#99764D] font-normal">
          Та "MNT хүлээн авсан" товч дарахаасаа өмнө мөнгө хүлээн авсан эсэхээ сайтар нягтлана уу. Гүйлгээний дүн болон утга хоёрыг тулгаж харна уу. Банкны SMS болон Email мэдэгдэлийг хуурамчаар үйлдэхэд хялбар ту банкны дансаа шалгана уу.
        </p>
      </div>

      {!escrow.sent && ((escrow?.type === "buy" && escrow?.maker.id === user.uid) || (escrow?.type === "sell" && escrow?.taker.id === user.uid)) && <>
        <button className="button mt-6 w-full" onClick={sent}>
          {sending && <>
            <svg className="inline animate-spin mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.5" d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z" fill="#71CBFE" />
              <path d="M20 12H22C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12Z" fill="#13A9FD" />
            </svg>
          </>}
          {escrow?.currency} явуулсан
        </button><br />
      </>}

      {escrow.sent && !escrow.received && ((escrow?.type === "buy" && escrow?.taker.id === user.uid) || (escrow?.type === "sell" && escrow?.maker.id === user.uid)) && <>
        <button className="button mt-6 w-full" onClick={received}>
          {receiving && <>
            <svg className="inline animate-spin mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.5" d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z" fill="#71CBFE" />
              <path d="M20 12H22C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12Z" fill="#13A9FD" />
            </svg>
          </>}
          {escrow?.currency} хүлээн авсан
        </button><br />
      </>}

      {escrow?.completed && <>Уг эскроу данс амжилттай суллагдсан байна!!!<br /></>}

      {escrow?.timedOut && <>
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 10.5v3.75m-9.303 3.376C1.83 19.126 2.914 21 4.645 21h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 4.88c-.866-1.501-3.032-1.501-3.898 0L2.697 17.626zM12 17.25h.007v.008H12v-.008z" />
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">Хугацаа дууссан</h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">Уг эскроу данс бүтэлгүйтсэн байна. Хэрэв танд ямар нэг асуудал үүссэн, жишээ нь таны мөнгө түгжигдсэн бол доорх холбоос дээр дарж бидэнтэй Telegram-аар холбогдоно уу!</p>
                        <a className="font-bold text-gray-500" href="https://t.me/Altexsupport">AltPay Support</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <Link to="/" className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm">Нүүрлүү буцах</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>}

      {successModalOpen && <>
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="28" cy="28" r="28" fill="#E6F9F9" />
                        <circle cx="28" cy="28" r="23" fill="#CCF4F3" />
                        <circle cx="28" cy="28" r="13" fill="white" />
                        <path d="M28 10.5C32.6413 10.5 37.0925 12.3437 40.3744 15.6256C43.6563 18.9075 45.5 23.3587 45.5 28C45.5 32.6413 43.6563 37.0925 40.3744 40.3744C37.0925 43.6563 32.6413 45.5 28 45.5C23.3587 45.5 18.9075 43.6563 15.6256 40.3744C12.3437 37.0925 10.5 32.6413 10.5 28C10.5 23.3587 12.3437 18.9075 15.6256 15.6256C18.9075 12.3437 23.3587 10.5 28 10.5ZM25.82 31.4525L21.9325 27.5625C21.7931 27.4231 21.6277 27.3126 21.4456 27.2372C21.2635 27.1617 21.0683 27.1229 20.8713 27.1229C20.6742 27.1229 20.479 27.1617 20.2969 27.2372C20.1148 27.3126 19.9494 27.4231 19.81 27.5625C19.5285 27.844 19.3704 28.2257 19.3704 28.6238C19.3704 29.0218 19.5285 29.4035 19.81 29.685L24.76 34.635C24.899 34.7751 25.0643 34.8862 25.2464 34.9621C25.4286 35.038 25.6239 35.077 25.8212 35.077C26.0186 35.077 26.2139 35.038 26.3961 34.9621C26.5782 34.8862 26.7435 34.7751 26.8825 34.635L37.1325 24.3825C37.2737 24.2437 37.3861 24.0783 37.4631 23.8959C37.5401 23.7135 37.5802 23.5176 37.5811 23.3196C37.582 23.1216 37.5437 22.9254 37.4684 22.7422C37.3931 22.5591 37.2823 22.3927 37.1424 22.2526C37.0025 22.1125 36.8362 22.0015 36.6531 21.926C36.4701 21.8505 36.2739 21.812 36.0759 21.8126C35.8779 21.8133 35.682 21.8532 35.4995 21.93C35.317 22.0068 35.1515 22.1189 35.0125 22.26L25.82 31.4525Z" fill="#00C6C4" />
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">Success</h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">Successfully transfered.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button type="button" className="button inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm" onClick={() => setSuccessModalOpen(false)}>OK</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>}

      {error && <div className="text-red-500">{error}</div>}
      <br />
    </>
  );
}

export default Escrow;