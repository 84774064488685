import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "./firebase";

export const getOrder = async (data) => {
  const type = data.type === "sell" ? "buy" : "sell";
  let q;

  if (data.amount) {
    q = query(
      collection(db, "orders"),
      where("type", "==", type),
      where("currency", "==", data.currency),
      where("amount", ">=", data.amount)
    );
  } else {
    q = query(
      collection(db, "orders"),
      where("type", "==", type),
      where("currency", "==", data.currency),
      where("amountAsCurrency", ">=", parseFloat(data.amountAsCurrency))
    );
  }

  const querySnapshot = await getDocs(q);
  let orders = [];

  querySnapshot.forEach((doc) => {
    orders.push({
      ref: doc.ref,
      id: doc.id,
      ...{
        ...doc.data(),
        ...{
          createdAt: doc.data().createdAt.toMillis()
        }
      }
    });
  });

  orders.sort((a, b) => a.price - b.price || a.createdAt - b.createdAt);

  return orders[0];
}
