const { Link } = require("react-router-dom");

const Head = ({link, children}) => {
  return (
    <>
      <div className="relative -mx-4 mb-6 flex justify-between space-x-3 items-center px-4 py-6 text-white text-2xl font-medium">
        <div className="absolute mx-[calc(-50vw+50%)] left-0 max-w-[100vw] w-screen h-full bg-gradient-radial-next from-[#0566CD] to-[#050F36] z-0"></div>
        <Link to={link || "/"}>
          <svg className="relative" width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 1L2 7.5L8 14" stroke="#E7F6FF" strokeWidth="2" />
          </svg>
        </Link>
        <div className="relative grow text-center">
          {children}
        </div>
        <div></div>
      </div>
    </>
  );
}

export default Head;