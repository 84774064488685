import { getFunctions, httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import Head from "./Head";
import { USDT_DECIMAL } from "../constants";
import { doc, onSnapshot } from "firebase/firestore";
import { auth, db } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

// TODO:
// Scan QR
const Withdraw = () => {
  const [user] = useAuthState(auth);
  const [balance, setBalance] = useState("...");
  const [toAddress, setToAddress] = useState();
  const [amount, setAmount] = useState("");
  const [txHash, setTxHash] = useState();
  const [withdrawing, setWithdrawing] = useState(false);
  const [errorAddress, setErrorAddress] = useState("");
  const [errorAmount, setErrorAmount] = useState("");
  const [errorCode, setErrorCode] = useState("");
  const [withdrawTimestamp, setWithdrawTimestamp] = useState();
  const [code, setCode] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const functions = getFunctions();

  useEffect(() => {
    return onSnapshot(doc(db, "users", user.uid), (userDoc) => {
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setBalance(userData.balance / 10**USDT_DECIMAL);
        setEmail(userData.email);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const withdraw = () => {
    setErrorAddress("");
    setErrorAmount("");
    setErrorCode("");
    setError("");
    let allValid = true;

    if (!/^0x[a-fA-F0-9]{40}$/.test(toAddress)) {
      setErrorAddress("Зөв хаяг оруулна уу!");
      allValid = false;
    }

    if (!toAddress) {
      setErrorAddress("Хаяг аа оруулна уу!");
      allValid = false;
    }

    if (amount > balance) {
      setErrorAmount("Үлдэгдэл хүрэлцэхгүй байна!");
      allValid = false;
    }

    if (amount < 100) {
      setErrorAmount("Хамгийн багадаа 100 USDT!");
      allValid = false;
    }

    if (!amount) {
      setErrorAmount("Дүнгээ оруулна уу!");
      allValid = false;
    }

    if (codeSent && !/^[a-f0-9]{6}$/.test(code)) {
      setErrorCode("Баталгаажуулах код тоо болон a-f хүртэлх жижиг үсэг орсон 6 оронтой байх ёстой!");
      allValid = false;
    }

    if (codeSent && !code) {
      setErrorCode("Баталгаажуулалтын кодоо оруулна уу!");
      allValid = false;
    }

    if ((codeSent && allValid && window.confirm("Та гаргахдаа итгэлтэй байна уу?")) || (!codeSent && allValid)) {
      if (!withdrawing) {
        setWithdrawing(true);
        const withdrawFunction = httpsCallable(functions, "withdraw");
        const timestamp = withdrawTimestamp || Date.now();
        setWithdrawTimestamp(timestamp);
        console.log(amount * 10**USDT_DECIMAL + "");
        withdrawFunction({
          to: toAddress,
          amount: amount * 10**USDT_DECIMAL + "",
          timestamp: timestamp,
          code: code,
        }).then((result) => {
          setCodeSent(true);
          setTxHash(result.data);
        }).catch((e) => {
          setError(e.message);
        }).finally(() => {
          setWithdrawing(false);
        });
      }
    }
  }

  const resendCode = () => {
    setCode("");
    setCodeSent(false);
    withdraw();
  }

  const amountSetter = (e) => {
    const value = e.target.value;
    if (/^[0-9]*\.?[0-9]*$/.test(value)) {
      setAmount(value);
    }
  }

  const codeSetter = (e) => {
    const value = e.target.value;
    if (/^[a-f0-9]{0,6}$/.test(value)) {
      setCode(value);
    }
  }

  return (
    <>
      <Head>Гаргах</Head>

      <p className="text-lg font-medium">USDT-гээ крипто хаягруу гаргах</p>

      <div className="p-4 mt-6 rounded-lg bg-[#FFF6E6] text-[#6D3B00] text-xs font-medium">
        <svg className="inline mr-2 align-middle" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_214_1003)">
            <path d="M7.99992 14.6667C11.6818 14.6667 14.6666 11.6819 14.6666 8.00001C14.6666 4.31811 11.6818 1.33334 7.99992 1.33334C4.31802 1.33334 1.33325 4.31811 1.33325 8.00001C1.33325 11.6819 4.31802 14.6667 7.99992 14.6667Z" stroke="#6D3B00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 5.33334V8.00001" stroke="#6D3B00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 10.6667H8.0075" stroke="#6D3B00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_214_1003">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
        Анхааруулга<br />
        <p className="mt-2 text-[#99764D] font-normal">
          Зөвхөн Ethereum сүлжээн дээрх ERC-20 USDT-г дэмжинэ.<br />
          Бусад тохиолдолд таны хөрөнгө алдагдана!!!
        </p>
      </div>

      <div className="mt-4 font-bold text-[#373F5E]">Хаяг</div>
      <div className="relative mt-2 mb-6">
        <input className={`input mt-0 py-[15px] bg-white rounded-lg drop-shadow-[0_8px_24px_rgba(5,102,205,0.16)] text-[#5F657E] leading-4 text-[14px] ${errorAddress ? "border-red-600" : "border-0"}`} placeholder="0x123...abc" type="text" defaultValue={toAddress} onChange={(e) => setToAddress(e.currentTarget.value)} />
        <svg className="hidden absolute top-[11px] right-[16px]" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="24" rx="4" fill="#E7F6FF" />
          <g clipPath="url(#clip0_230_2386)">
            <path d="M9.027 8.79052L9.2907 7.47141C9.34442 7.20263 9.48964 6.96079 9.70163 6.78705C9.91362 6.6133 10.1793 6.51841 10.4534 6.51852H14.4356C14.7097 6.51841 14.9753 6.6133 15.1873 6.78705C15.3993 6.96079 15.5445 7.20263 15.5983 7.47141L15.862 8.79052C15.9034 8.99721 16.0026 9.18791 16.1481 9.34043C16.2936 9.49296 16.4794 9.60106 16.6839 9.65215C17.1657 9.77267 17.5933 10.0508 17.8989 10.4422C18.2045 10.8337 18.3704 11.3161 18.3704 11.8127V16C18.3704 16.6287 18.1207 17.2316 17.6761 17.6761C17.2316 18.1206 16.6287 18.3704 16 18.3704H8.88892C8.26026 18.3704 7.65735 18.1206 7.21282 17.6761C6.76829 17.2316 6.51855 16.6287 6.51855 16V11.8127C6.51851 11.3161 6.68447 10.8337 6.99005 10.4422C7.29563 10.0508 7.7233 9.77267 8.20507 9.65215C8.40958 9.60106 8.59539 9.49296 8.74089 9.34043C8.88639 9.18791 8.9856 8.99721 9.027 8.79052V8.79052Z" stroke="#13A9FD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.4446 16C13.7537 16 14.815 14.9387 14.815 13.6296C14.815 12.3205 13.7537 11.2593 12.4446 11.2593C11.1355 11.2593 10.0742 12.3205 10.0742 13.6296C10.0742 14.9387 11.1355 16 12.4446 16Z" stroke="#13A9FD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.8521 8.88889H13.0372" stroke="#13A9FD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_230_2386">
              <rect width="14.2222" height="14.2222" fill="white" transform="translate(5.3335 5.33333)" />
            </clipPath>
          </defs>
        </svg>
        {errorAddress && <div className="text-red-500">{errorAddress}</div>}
      </div>

      <div className="mb-10">
        <div className="text-[#373F5E]">Дүн</div>
        <div className="flex items-center relative">
          <div className="grow relative">
            <input className={`input border-r-0 rounded-r-none h-[66px] ${errorAmount && "border-red-600"}`} placeholder="123" value={amount} onChange={amountSetter} />
            <div className="absolute rounded bg-gray-300 px-[10px] py-[5px] top-[28px] right-[15px] text-white cursor-pointer text-[12px]" onClick={() => setAmount(balance)}>MAX {Math.floor(balance * Math.pow(10, 2)) / Math.pow(10, 2)}</div>
          </div>
          <div className="border-[#0566CD] border-y h-[66px] flex items-center">
            <div className="border-[#0566CD] border-r h-10"></div>
          </div>
          <div className={`input border-l-0 rounded-l-none h-[66px] !w-20 text-[#0566CD] appearance-none ${errorAmount && "border-red-600"}`}>
            USDT
          </div>
        </div>
        <div className="text-[10px] text-gray-500">Багадаа 100 USDT. Шимтгэл 16 USDT.</div>
        {errorAmount && <div className="text-red-500">{errorAmount}</div>}
      </div>

      {codeSent && <>
        <div className="mb-10">
          <div className="text-[#373F5E]">Баталгаажуулах код</div>
          <div className="relative">
            <input className={`input h-[66px] ${errorCode && "border-red-600"}`} placeholder="1a2b3c" value={code} onChange={codeSetter} />
            <div className="absolute rounded bg-gray-300 px-[10px] py-[5px] top-[28px] right-[15px] text-white cursor-pointer text-[12px]" onClick={resendCode}>Дахин явуулах</div>
          </div>
          <div className="text-[10px] text-gray-500">Таны {email} хаягруу баталгаажуулах код илгээсэн.</div>
          {errorCode && <div className="text-red-500">{errorCode}</div>}
        </div>
      </>}

      <button className="button w-full disabled:from-white" disabled={withdrawing} onClick={withdraw}>
        {withdrawing && <>
          <svg className="animate-spin mr-1 inline" width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.5" d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z" fill="#71CBFE" />
            <path d="M20 12H22C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12Z" fill="#13A9FD" />
          </svg>
        </>}
        {codeSent ? <>
          Гаргах
        </> : <>
          Баталгаажуулах код авах
        </>}
      </button><br />
      {error && <div className="rounded bg-red-300 mt-[10px] text-red-600 px-[15px] py-[10px] break-words">{error}</div>}
      {txHash && <>
        <div className="rounded bg-green-300 mt-[10px] text-green-600 px-[15px] py-[10px] break-words">
          <strong>Амжилттай</strong> Hash: <a href={"https://testnet.bscscan.com/tx/"+txHash} className="underline">{txHash}</a>
        </div>
      </>}
      <br />
    </>
  )
}

export default Withdraw;